import React from 'react';
import RentCardDetails from './RentCardDetails/RentCardDetails';

const RentDetail: React.FC = () => {
  return (
    <div>
      <RentCardDetails />
    </div>
  );
};

export default RentDetail;

import 'aos/dist/aos.css';

import './Home.css';
import React from 'react';
//import './snowflake.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import logo from './assets/logo.svg';
import homeLine from './assets/homeLine.png';
import service24 from './assets/24FAHRDIENST.png';
// import cars24 from './assets/cars';
import cars24 from './assets/rent24/cars24Logo.png';
import rent24 from './assets/rent24/rent24Logo.png';
import footerLine from './assets/footer-line.png';
import partnersEHL from './assets/partners/EHL.png';
import partnersChurBus from './assets/partners/ChurBus.png';
import partnersKantonsspital from './assets/partners/KantonsspitalGraubunden.png';
import partnersKlinikGut from './assets/partners/KlinikGut.png';
import compassGroup from './assets/partners/compassGroup.webp';
import partnersRHB from './assets/partners/RHB.png';
import gifArrow from './assets/gifs/downarrow.gif';
import landingPage2 from './assets/landing-page-line2.png';
import landingPage4 from './assets/landing-page-line4.png';
import auftragsbestatigungIcon from './assets/icons/serviceIcons/AuftragsbestatigungIcon.svg';
import planungIcon from './assets/icons/serviceIcons/PlanungIcon.svg';
import arbeitIcon from './assets/icons/serviceIcons/ArbeitIcon.svg';
import analyseIcon from '../pages/assets/icons/serviceIcons/AnalyseIcon.svg';
const Home: React.FC = () => {
  return (
    <div>
      <div className='container-fluid'>
        <div
          className='home'
          style={{
            height: '92vh',
            display: 'grid',
            placeContent: 'center',
            // paddingTop: '7vh',
          }}
        >
          <div className='textHeader'>
            <div className='row'>
              <div className='col-md'>
                <div data-aos='fade-right' data-aos-duration='2000'>
                  <span
                    style={{
                      fontSize: '45px',
                      color: 'white',
                      fontFamily:
                        'Impact, Haettenschweiler, Arial Narrow Bold, sans-serif',
                    }}
                  >
                    Willkommen bei
                  </span>
                  <br />
                  <span
                    style={{
                      fontSize: '50px',
                      color: 'white',
                      fontFamily:
                        'Impact, Haettenschweiler, Arial Narrow Bold, sans-serif',
                    }}
                  >
                    Group24
                  </span>
                  <br />
                  <img
                    src={homeLine}
                    style={{ width: '40vh' }}
                    alt='Home Line'
                  />
                </div>
              </div>
              <div className='col-md'></div>
            </div>
          </div>
        </div>
        <div className='column' style={{ height: '220px' }}>
          <div
            style={{
              marginTop: '4vh',
              marginBottom: '2vh',
              textAlign: 'center',
            }}
          >
            <div>
              <span
                style={{
                  fontSize: '30px',
                  backgroundColor: 'rgba(255, 255, 255, 0.2)',
                  color: 'rgba(0, 0, 0, 0.9)',
                  fontWeight: 500,
                }}
              >
                Ein Überblick unserer <br />
                Dienstleistungen
              </span>
            </div>
          </div>
          <div className='col-md'>
            <div style={{ marginRight: 30 }}>
              <div data-aos='fade-up' data-aos-duration='2000'>
                <svg className='arrows'>
                  <path className='a1' d='M0 0 L30 32 L60 0'></path>
                  <path className='a2' d='M0 20 L30 52 L60 20'></path>
                  <path className='a3' d='M0 40 L30 72 L60 40'></path>
                </svg>
              </div>
            </div>
          </div>
          {/* <div style={{ textAlign: 'center', marginBottom: '50px' }}>
            <img src={gifArrow} />
          </div> */}
        </div>
        <div className='row services-section' id='services'>
          <div className='col-md first-service'>
            <a href='./fahrdienst'>
              <div>
                <img src={service24} alt='24FAHRDIENST' />
                <span className='service-info'>
                  Verlassen Sie sich auf den 24-Stunden Service während 365 Tage
                  im Jahr.
                </span>
              </div>
            </a>
          </div>
          <div className='col-md second-service'>
            <a href='https://www.cars-24.ch'>
              <div>
                <img src={cars24} alt='Cars 24' />
                <span className='service-info'>Ihr Autohändler in Chur.</span>
              </div>
            </a>
          </div>
          <div className='col-md third-service'>
            <a href='./rent'>
              <div>
                <img src={rent24} alt='Rent 24' />
                <span className='service-info'>
                  Transportlösungen für jeden Anlass – von Kleinbus bis
                  Transporter!
                </span>
              </div>
            </a>
          </div>{' '}
        </div>
        <div className='container'>
          <div className='row d-flex third-section mb-4'>
            <div className='third-section-content'>
              <h3>
                Wir befolgen einen bestimmten{' '}
                <b>Ablauf für die Abwicklung unserer Aufträge.</b>
              </h3>
              <img src={landingPage2} alt='Line' />
            </div>
            <div className='row mt-5 d-flex justify-content-center align-items-center'>
              <div className='col-md-6 col-lg-6'>
                <div data-aos='flip-down' data-aos-duration='1000'>
                  <img
                    src={auftragsbestatigungIcon}
                    alt='Auftragsbestätigung'
                  />
                  <h4>Auftragsbestätigung</h4>
                  <span>Wir nehmen den Auftrag entgegen.</span>
                </div>
              </div>
              <div className='col-md-6 col-lg-6'>
                <div data-aos='flip-down' data-aos-duration='1200'>
                  <img src={analyseIcon} />
                  <h4>Analyse</h4>
                  <span>Die Aufgaben werden überprüft und analysiert.</span>
                </div>
              </div>
              <div className='col-md-6 col-lg-6'>
                <div data-aos='flip-down' data-aos-duration='1400'>
                  <img src={planungIcon} alt='Planung' />
                  <h4>Planung</h4>
                  <span>Die nötigen Mittel und Personal wird organisiert.</span>
                </div>
              </div>
              <div className='col-md-6 col-lg-6'>
                <div data-aos='flip-down' data-aos-duration='1600'>
                  <img src={arbeitIcon} alt='Arbeit' />
                  <h4>Arbeit</h4>
                  <span>Wir arbeiten stets sauber und professionell.</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='row fifth-section' style={{ background: '#ff000b' }}>
          <div className='d-flex flex-column' style={{ alignItems: 'center' }}>
            <h3>
              Unsere <b>Partner</b>
            </h3>
            <img src={landingPage4} alt='Line' />
          </div>
          <div className='partners'>
            <a href='#'>
              <img
                src={compassGroup}
                style={{ width: '170px' }}
                alt='Compass Group'
              />
            </a>
            <a href='#'>
              <img src={partnersEHL} style={{ width: '160px' }} alt='EHL' />
            </a>
            <a href='#'>
              <img
                src={partnersChurBus}
                style={{ width: '190px' }}
                alt='ChurBus'
              />
            </a>
            <a href='#'>
              <img
                src={partnersKantonsspital}
                style={{ width: '170px' }}
                alt='Kantonsspital Graubunden'
              />
            </a>
            <a href='#'>
              <img
                src={partnersKlinikGut}
                style={{ width: '180px' }}
                alt='Klinik Gut'
              />
            </a>
            <a href='#'>
              <img src={partnersRHB} style={{ width: '170px' }} alt='RHB' />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;

import React, { useEffect, useState } from 'react';
import 'yet-another-react-lightbox/styles.css';
import './RentCardDetails.css';
import { useNavigate, useParams } from 'react-router-dom';
import Sprinter from '../../../assets/rent24/sprinter.jpg';
import Shuttle from '../../../assets/rent24/shuttle.jpg';
import Vito from '../../../assets/rent24/vito.jpg';
import VitoInside from '../../../assets/rent24/insideVito.jpg';
import ShuttleInside from '../../../assets/rent24/insideShuttle.jpg';
import SprinterInside from '../../../assets/rent24/insideSprinter.jpg';
import DatePicker from 'react-datepicker';
import { addDays, format, isWeekend } from 'date-fns';

const rentals = {
  rent: [
    {
      rentId: 1,
      name: 'Sprinter',
      price: 120,
      description:
        'Mieten Sie unseren Mercedes Sprinter für zuverlässige und großzügige Transporte! Mit viel Platz und Komfort ist er ideal für Umzüge, Frachttransporte oder als vielseitiges Arbeitsfahrzeug. Profitieren Sie von seiner Robustheit und Flexibilität für all Ihre Bedürfnisse.',
      image: Sprinter,
      thumbnail: [
        {
          image: Sprinter,
        },
        {
          image: SprinterInside,
        },
      ],
      priceTime: [
        {
          time: 6,
          price: 120,
        },
        {
          time: 12,
          price: 160,
        },
        {
          time: 24,
          price: 220,
        },
      ],
    },
    {
      rentId: 2,
      name: 'Shuttle',
      price: 140,
      description:
        'Mieten Sie unseren geräumigen 14-Sitzer Van für komfortable Gruppenreisen und Ausflüge! Mit Platz für bis zu 14 Personen eignet sich dieses Fahrzeug ideal für Familienurlaube, Vereinsausflüge oder Transport von Gruppen. Genießen Sie den Komfort und die Vielseitigkeit, die unser Van bietet.',
      image: Shuttle,
      thumbnail: [
        {
          image: Shuttle,
        },
        {
          image: ShuttleInside,
        },
      ],
      priceTime: [
        {
          time: 6,
          price: 140,
        },
        {
          time: 12,
          price: 200,
        },
        {
          time: 24,
          price: 280,
        },
      ],
    },
    {
      rentId: 3,
      name: 'Vito',
      price: 120,
      description:
        'Mieten Sie unseren komfortablen 9-Sitzer Van für Ihre Gruppenreisen und Ausflüge! Ideal für Familienurlaube, Freunde oder kleine Gruppen, bietet dieser Van genügend Platz und Komfort für eine entspannte Fahrt. Perfekt für alle, die gemeinsam unterwegs sein möchten.',
      image: Vito,
      thumbnail: [
        {
          image: Vito,
        },
        {
          image: VitoInside,
        },
      ],
      priceTime: [
        {
          time: 6,
          price: 120,
        },
        {
          time: 12,
          price: 180,
        },
        {
          time: 24,
          price: 250,
        },
      ],
    },
  ],
};
const Gallery = () => {
  const { rentId } = useParams<{ rentId?: string }>();
  const selectedRental = rentals.rent.find(
    (rent) => rent.rentId === Number(rentId)
  );
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [size, setSize] = useState<number>(6);
  const [price, setPrice] = useState<number | undefined>(selectedRental?.price);
  console.log('price', price);
  const [phoneNumber, setPhoneNumber] = useState<string>('+41 ');
  const [name, setName] = useState<string>('');
  const [mailto, setEmail] = useState<string>('');
  const isWeekEnd = (date: any) => isWeekend(date);
  const handleClick = (size: number) => {
    const Price = selectedRental?.priceTime.find(
      (price) => price.time === size
    );
    setPrice(Price?.price);
    setSize(size);
  };
  useEffect(() => {
    window.scrollTo(0, 0);

    const today = new Date();

    let date = today;
    while (!isWeekend(date)) {
      date = addDays(date, 1);
    }

    setStartDate(date);
  }, []);

  const [mainImage, setMainImage] = useState(selectedRental?.image);
  const phoneRegex = /^(?:\+41|0041)?(?:\s?\d{2,3}){2}\s?\d{2}\s?\d{2}$/;

  const handleThumbnailClick = (e: any) => {
    setMainImage(() => e.target.dataset.url);
  };

  const handleAddToCart = () => {
    if (phoneRegex.test(phoneNumber)) {
      window.location.href = `mailto:info@group24.ch?subject=Mietfahrzeug%20anfordern%20&body=Hallo%20Rent24%20Team,%0A%0AIch%20${
        name ? `%20bin%20${name}%20und%20` : ''
      }möchte%20eines%20Ihrer%20Fahrzeuge%20mieten.%20Hier%20finden%20Sie%20die%20Details:%0A%0A%20Fahrzeug:%20${
        selectedRental?.name
      }%0A%0ADatum:%20${format(
        startDate,
        'MMMM-dd-yyyy'
      )}%0A%0AZeit:%20${size}%20Std.%0A%0AMeine%20Telefonnummer:%20${phoneNumber}%0A%0A`;
    } else alert('Die Rufnummer ist ungültig.');
  };

  return (
    <div className='hero'>
      <div className='gallery'>
        <div className='img-wrapper'>
          <div className='main-img'>
            <img src={mainImage} alt='shoes' />
          </div>

          <div className='thumbnails'>
            {selectedRental?.thumbnail.map((rent) => (
              <img
                onClick={handleThumbnailClick}
                src={rent.image}
                data-url={rent.image}
                data-index='0'
                alt='shoes'
                className={
                  mainImage === selectedRental?.image ? 'active-thumb' : ''
                }
              />
            ))}
          </div>
        </div>
      </div>
      <div className='details-wrapper'>
        <p className='details-company'>Rent24</p>
        <h1 className='details-product-name'>{selectedRental?.name}</h1>
        <p className='details-description'>{selectedRental?.description}</p>

        <div className='price'>
          {/* DISCOUNT */}
          {/* <h2 className='price-new'>
            $125.00 <span className='discount'>50%</span>
          </h2>
          <p className='price-old'>$250.00</p> */}
          <p className='price-new'>CHF {Number(price).toFixed(2)}</p>
        </div>

        <div className='buy'>
          <div>
            <div className='phoneNumber'>
              <label>Name:</label>{' '}
              <input
                type='text'
                name='name'
                placeholder='Ihr Name(optional)'
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
            <div className='calendar'>
              <label>Datum:</label>{' '}
              <DatePicker
                selected={startDate}
                onChange={(date: any) => setStartDate(date)}
                startDate={startDate}
                filterDate={isWeekEnd}
                minDate={new Date()}
                placeholderText='Select start date'
              />
            </div>
          </div>
          <div className='phoneNumber'>
            <label>Telefonnummer:</label>{' '}
            <input
              type='tel'
              id='phone'
              name='phone'
              placeholder='Add phone number'
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              required
            />
          </div>

          <div className='size-container'>
            <div
              className={`size ${size === 6 ? 'selected' : ''}`}
              onClick={() => handleClick(6)}
            >
              6 Stunden
            </div>
            <div
              className={`size ${size === 12 ? 'selected' : ''}`}
              onClick={() => handleClick(12)}
            >
              12 Stunden
            </div>
            <div
              className={`size ${size === 24 ? 'selected' : ''}`}
              onClick={() => handleClick(24)}
            >
              24 Stunden
            </div>
          </div>
          <a className='add-to-cart' onClick={handleAddToCart}>
            E-Mail an uns
          </a>
          <div style={{ color: 'lightslategray', marginTop: 10 }}>
            oder kontaktieren Sie uns unter:{' '}
            <a
              href='tel:+41814042425'
              style={{ textDecoration: 'none', fontSize: 18 }}
            >
              +41814042425
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gallery;

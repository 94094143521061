import React from 'react';
import footerLine from '../../pages/assets/footer-line.png';
import footerLogo from '../../pages/assets/footerLogo.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Footer.css';

export const Footer = () => {
  return (
    <footer style={{ marginTop: '-5px', position: 'relative', zIndex: '1' }}>
      <div
        style={{
          backgroundColor: 'black',
          paddingTop: '20px',
          paddingBottom: '20px',
        }}
      >
        <div className='row' style={{ marginRight: '0px' }}>
          <div className='col-sm' style={{ textAlign: 'center' }}>
            <a href='#'>
              <img src={footerLogo} alt='Group24' style={{ width: '50%' }} />
            </a>
            <div
              className='iconsContainer'
              style={{ marginTop: '10px', justifyContent: 'center' }}
            >
              <a href='https://www.facebook.com/www.group24.ch'>
                <i className='facebookIcon' />
              </a>
              <a href='https://www.instagram.com/group24.ch'>
                <i className='instagramIcon' />
              </a>
            </div>
          </div>
          <div className='col-sm' style={{ textAlign: 'center' }}>
            <div className='row'>
              <div className='col-sm'></div>
              <div className='col-sm' style={{ marginTop: '20px' }}>
                <h5 className='text'>Firma</h5>
                <img src={footerLine} style={{ width: '100px' }} />
                <a href='./../Home'>
                  <div className='text'>Group 24 GmbH</div>
                </a>
                <a href='./../Fahrdienst'>
                  <div className='text'>Fahrdienst24</div>
                </a>
                <a href='https://www.cars-24.ch'>
                  <div className='text'>Cars24</div>
                </a>
                <a href='./../Rent'>
                  <div className='text'>Rent24</div>
                </a>
                <a href='./../Uberuns'>
                  <div className='text'>Über Uns</div>
                </a>
              </div>
            </div>
          </div>
          <div className='col-sm' style={{ textAlign: 'center' }}>
            <div className='row'>
              <div className='col-sm' style={{ marginTop: '20px' }}>
                <h5 className='text'>Standort</h5>
                <img src={footerLine} style={{ width: '100px' }} />
                <div className='text'>Triststrasse 8</div>
                <div className='text'>7000 Chur</div>
                <div className='text'>Schweiz</div>
              </div>
              <div className='col-sm' style={{ marginTop: '20px' }}>
                <h5 className='text'>Kontakt</h5>
                <img src={footerLine} style={{ width: '100px' }} />
                <div className='text'>Telefonnummer</div>
                <div className='text'>+41814042425</div>
                <div className='text'>Email</div>
                <div className='text'>info@group24.ch</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

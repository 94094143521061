import React from 'react';
import './RentCard.css';
import { Link } from 'react-router-dom';

interface ProductCardProps {
  id: number;
  name: string;
  price: number;
  image: string;
  description: string;
}
const ProductCard: React.FC<ProductCardProps> = ({
  id,
  name,
  price,
  image,
  description,
}) => {
  return (
    <div className='product-card'>
      <img className='product-image' src={image} alt={name} />
      <div
        style={{ padding: 10, backgroundColor: '#f8fcff', borderRadius: 10 }}
      >
        <p className='product-name'>{name}</p>
        <p className='product-description'>{description}</p>
        <p className='product-price'>ab CHF {Number(price).toFixed(2)}</p>
        <Link to={`/rent/${id}`}>
          <button className='add-to-cart-btn'>mehr Details</button>
        </Link>
      </div>
    </div>
  );
};

export default ProductCard;

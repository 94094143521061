import React from 'react';
import './Home.css';
import image from '../pages/assets/landing-page-line8.png';
import grayLine from '../pages/assets/gray_line.png';
import ceoImage from '../pages/assets/rent24/ceo.jpg';

const HomePage: React.FC = () => {
  return (
    <div>
      <div className='container-fluid'>
        <div className='row about-us-section'>
          <div className='col-lg-6 p-0 main-section'>
            <div className='headerContent'>
              <div>
                <p style={{ fontSize: '25px' }}>Über Uns</p>
                <p className='font-weight-bold' style={{ fontSize: '60px' }}>
                  Group24
                </p>
              </div>
              <img src={image} alt='Line' className='mt-2 mb-2' />
              <span className='descriptionText'>
                Das höchste Anliegen der Group24 ist es, unseren Kunden eine
                sehr gute Serviceleistung zu erbringen.
                <p>
                  Unsere Mission ist es, unseren Kunden die besten Erfahrungen
                  zu bieten.
                </p>
              </span>
            </div>
          </div>
          <div className='col-lg-6 p-0 main-section'></div>
        </div>
        <div className='container'>
          <div className='d-flex flex-column justify-content-center align-items-center team-section'>
            <h3>Geschäftsleitung</h3>
            <div
              className='row w-100 mt-5'
              style={{ justifyContent: 'center' }}
            >
              <div className='col-md-3 d-flex flex-column justify-content-center align-items-center member'>
                <img src={ceoImage} className='member-image' />
                <h4 className='mb-1'>Florim Selimi</h4>
                {/* <h6 style={{ fontWeight: 600, marginBottom: 20 }}>CEO</h6> */}
                <div className='team-icons' style={{ marginTop: 10 }}>
                  <a href='mailto:florim.selimi@group24.ch'>
                    <i className='fa fa-envelope'></i>
                  </a>
                  <a href='tel:+41814042425'>
                    <i className='fa fa-phone'></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div style={{ textAlign: 'center', marginTop: '50px' }}>
          <h3>
            Über <b>uns</b>
          </h3>
          <br />
          <img src={grayLine} style={{ maxWidth: '350px' }} />
          <p
            style={{
              paddingLeft: '17%',
              paddingRight: '17%',
              paddingTop: '3%',
              paddingBottom: '4%',
            }}
          >
            <b style={{ fontSize: '25px' }}> Fahrdienst24 </b>
            <br />
            Die Fahrdienste 24 ist im Jahre 2016 durch die Übernahme der Taxi
            Giulio AG, welche davor während 30 Jahren in Chur und Umgebung
            zuverlässig ihren Service angeboten hat, gegründet worden. Wir
            möchten Ihnen die Fahrt so angenehm und komfortabel wie nur möglich
            gestalten. Unsere Fahrer erhalten regelmässig Schulungen und sind
            äusserst zuvorkommend. Sie helfen Ihnen gern mit Ihrem Gepäck oder
            beraten Sie bei diversen Anliegen, auch kompetent bei ausländischen
            Gästen (unsere Chauffeure sprechen Deutsch, Englisch, Italienisch
            und Albanisch.)
            <br />
            <br />
          </p>
        </div> */}
      </div>
      <script
        src='https://code.jquery.com/jquery-3.3.1.slim.min.js'
        integrity='sha384-q8i/X+965DzO0rT7abK41JStQIAqVgRVzpbzo5smXKp4YfRvH+8abtTE1Pi6jizo'
        crossOrigin='anonymous'
      ></script>
      <script src='https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.3/umd/popper.min.js'></script>
      <script src='https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/js/bootstrap.min.js'></script>
      <script src='js/jquery-2.1.1.js'></script>
      <script src='js/jquery.mobile.custom.min.js'></script>
      <script src='js/main.js'></script>
    </div>
  );
};

export default HomePage;

import React, { useEffect } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles
import 'react-datepicker/dist/react-datepicker.css';

import Uberuns from './pages/Uberuns';
import Fahrdienst from './pages/services/Fahrdienst';
import Facility from './pages/services/Facility';
import Contact from './pages/Contact';
import { Header } from './components/Header/Header';
import { Footer } from './components/Footer/Footer';
import Home from './pages/Home';
import Rent from './pages/services/Rent/Rent';
import RentDetail from './pages/services/Rent/RentDetail';

const App: React.FC = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Duration of animations
      easing: 'ease-in-out', // Easing function
      once: true, // Animation will only occur once
    });
  }, []);

  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path={'/'} element={<Home />} />
        <Route path='/home' element={<Home />} />
        <Route path='/uberuns' element={<Uberuns />} />
        <Route path='/fahrdienst' element={<Fahrdienst />} />
        <Route path='/facility' element={<Facility />} />
        <Route path='/rent' element={<Rent />} />
        <Route path='/rent/:rentId' element={<RentDetail />} />
        <Route path='/contact' element={<Contact />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

export default App;

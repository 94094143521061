import React from 'react';
import logo from '../../pages/assets/logo.svg';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

export const Header = () => {
  return (
    <nav className='navbar sticky-top navbar-expand-lg navbar-light bg-light'>
      <div className='container'>
        <a className='navbar-brand' href='#'>
          <img src={logo} alt='Group24' style={{ height: '40px' }} />
        </a>
        <button
          className='navbar-toggler'
          type='button'
          data-bs-toggle='collapse'
          data-bs-target='#navbarSupportedContent'
          aria-controls='navbarSupportedContent'
          aria-expanded='false'
          aria-label='Toggle navigation'
        >
          <i className='fas fa-bars'></i>
        </button>
        <div className='collapse navbar-collapse' id='navbarSupportedContent'>
          <ul className='navbar-nav me-auto mb-2 mb-lg-0'>
            <li className='nav-item'>
              <a className='nav-link active' href='/home'>
                Home <span className='visually-hidden'>(current)</span>
              </a>
            </li>
            <li className='nav-item'>
              <a className='nav-link' href='/home#services'>
                Dienstleistungen
              </a>
            </li>
            <li className='nav-item'>
              <a className='nav-link' href='./Uberuns'>
                Über Uns
              </a>
            </li>
          </ul>
          <ul className='navbar-nav mb-2 mb-lg-0 ms-auto'>
            <li className='nav-item'>
              <a className='nav-link' href='./Contact'>
                Kontaktaufnahme
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './Home.css';
import emailIcon from '../pages/assets/email-us.png';
import callUs from '../pages/assets/call-us.png';

const App: React.FC = () => {
  return (
    <div>
      <div className='container-fluid'>
        <div className='container'>
          <div className='row d-flex third-section mb-4'>
            <div className='third-section-content'>
              <h2 className='font-weight-bold ct-header'>
                Kontaktieren Sie uns
              </h2>
            </div>
          </div>
        </div>
        <div className='d-flex flex-column justify-content-center align-items-center team-section'>
          <div className='row w-100 mt-5'>
            <div className='col-md-2 d-flex flex-column justify-content-center align-items-center'></div>
            <div className='col-md-4 d-flex flex-column justify-content-center align-items-center'>
              <a href='mailto:info@group24.ch'>
                <img src={emailIcon} className='member-image' alt='Email' />
              </a>
              <h4 className='mb-3'>Email</h4>
              <a href='mailto:info@group24.ch'>info@group24.ch</a>
            </div>
            <div className='col-md-4 d-flex flex-column justify-content-center align-items-center'>
              <a href='tel:+41814042425'>
                <img src={callUs} className='member-image' alt='Call' />
              </a>
              <h4 className='mb-3'>Telefon</h4>
              <a href='tel:+41814042425'>+41814042425</a>
            </div>
            <div className='col-md-2 d-flex flex-column justify-content-center align-items-center'></div>
          </div>
        </div>
        <div>
          <iframe
            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2728.944859674234!2d9.503988815344712!3d46.84477537914158!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4784c71a6da717b5%3A0x967545d596f747f!2sTriststrasse%208%2C%207000%20Chur%2C%20Switzerland!5e0!3m2!1sen!2s!4v1668417385241!5m2!1sen!2s'
            width='100%'
            height='450'
            style={{ border: 0 }}
            allowFullScreen
            loading='lazy'
            referrerPolicy='no-referrer-when-downgrade'
            title='Google Maps'
          ></iframe>
        </div>
      </div>

      <script src='https://code.jquery.com/jquery-3.3.1.slim.min.js'></script>
      <script src='https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.3/umd/popper.min.js'></script>
      <script src='https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/js/bootstrap.min.js'></script>
    </div>
  );
};

export default App;

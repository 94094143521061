import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './../style.css';
import './Rent.css';
import sprinterPhoto from '../../assets/rent24/sprinter.jpg';
import vitoPhoto from '../../assets/rent24/vito.jpg';
import shuttlePhoto from '../../assets/rent24/shuttle.jpg';
import RentCard from './RentCard';
import grayLine from '../../assets/gray-line.png';

const Rent: React.FC = () => {
  return (
    <div className='container-fluid'>
      <div className='rentBgLeft' />
      <div className='rentBgRight' />
      <div className='content'>
        <div className='row'>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div className='rentHeader'>
              <div>
                <h3 className='font-weight-bold'>Rent24</h3>
              </div>
              <img src={grayLine} alt='Line' />
              <span className='descriptionText' style={{ color: '#000' }}>
                Wir bieten drei vielseitige Vans an: einen 9-sitzigen Vito für
                kleine Gruppen, einen Transport-Sprinter für flexible Fracht-
                und Passagiernutzung sowie einen 14-sitzigen Sprinter für
                größere Gruppen und Events. Jeder Van ist darauf ausgelegt,
                verschiedene Reise- und Transportbedürfnisse zu erfüllen.
              </span>
            </div>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            margin: '30px 0px',
          }}
        >
          <RentCard
            name='Transport-Sprinter'
            description='Entwickelt für den Transport von sowohl Passagieren als auch Fracht, um vielfältige Transportbedürfnisse effizient zu erfüllen.'
            id={1}
            price={120}
            image={sprinterPhoto}
          />
          <RentCard
            name='9-sitziger Vito'
            description='Ideal für kleine Gruppen oder Familien, die zusätzlichen Platz und Komfort für mittelgroße Fahrten benötigen.'
            id={3}
            price={120}
            image={vitoPhoto}
          />
          <RentCard
            name='14-sitziger Sprinter'
            description='Perfekt für größere Gruppen oder Teamreisen, die viel Platz und Bequemlichkeit für Gruppenausflüge bieten.'
            id={2}
            price={140}
            image={shuttlePhoto}
          />
        </div>
        <div className='row black-row'>
          <div className='d-flex flex-column' style={{ alignItems: 'center' }}>
            <h3 className='black-section-title'>
              Warum mit uns zusammenarbeiten?
            </h3>
            <img className='grayLine' src={grayLine} />
          </div>
          <div className='partners'>
            <div>
              <h5>Ausgezeichneter & Zuverlässiger Service!</h5>
            </div>
            <div>
              <h4>Preiswert</h4>
            </div>
            <div>
              <h5>Qualität & Sauberkeit</h5>
            </div>
            <div>
              <h4>Komfort & Flexibilität</h4>
            </div>
          </div>
        </div>
        <div style={{ textAlign: 'center', marginBottom: 20 }}>
          <div className='maler_beratung_reinigung'>
            <div className='row mt-5'>
              <div
                className='col-lg-5'
                style={{
                  paddingTop: '80px',
                  paddingRight: '50px',
                  backgroundColor: 'rgb(247, 246, 246)',
                }}
              >
                <h4>
                  <b>Sprinter</b>
                </h4>
                <span>
                  Mieten Sie unseren Mercedes Sprinter für zuverlässige und
                  großzügige Transporte! Mit viel Platz und Komfort ist er ideal
                  für Umzüge, Frachttransporte oder als vielseitiges
                  Arbeitsfahrzeug. Profitieren Sie von seiner Robustheit und
                  Flexibilität für all Ihre Bedürfnisse.
                </span>
              </div>
              <div
                className='col-lg-4'
                style={{ backgroundColor: 'rgb(247, 246, 246)' }}
              >
                <img src={sprinterPhoto} className='reiningung-img' />
              </div>
            </div>
          </div>
          <div className='maler_beratung_reinigung'>
            <div className='row mt-5 middleSection'>
              <div
                className='col-lg-4 discussion'
                style={{ backgroundColor: 'rgb(247, 246, 246)' }}
              >
                <img src={shuttlePhoto} className='reiningung-img' />
              </div>
              <div
                className='col-lg-5'
                style={{
                  paddingTop: '80px',
                  backgroundColor: 'rgb(247, 246, 246)',
                }}
              >
                <h4>
                  <b>Shuttle</b>
                </h4>
                <span>
                  Mieten Sie unseren geräumigen 14-Sitzer Van für komfortable
                  Gruppenreisen und Ausflüge! Mit Platz für bis zu 14 Personen
                  eignet sich dieses Fahrzeug ideal für Familienurlaube,
                  Vereinsausflüge oder Transport von Gruppen. Genießen Sie den
                  Komfort und die Vielseitigkeit, die unser Van bietet.
                </span>
              </div>
            </div>
          </div>
          <div className='maler_beratung_reinigung'>
            <div className='row mt-5'>
              <div
                className='col-lg-5'
                style={{
                  paddingTop: '80px',
                  paddingRight: '50px',
                  backgroundColor: 'rgb(247, 246, 246)',
                }}
              >
                <h4>
                  <b>Vito</b>
                </h4>
                <span>
                  Mieten Sie unseren komfortablen 9-Sitzer Van für Ihre
                  Gruppenreisen und Ausflüge! Ideal für Familienurlaube, Freunde
                  oder kleine Gruppen, bietet dieser Van genügend Platz und
                  Komfort für eine entspannte Fahrt. Perfekt für alle, die
                  gemeinsam unterwegs sein möchten.
                </span>
              </div>
              <div
                className='col-lg-4'
                style={{ backgroundColor: 'rgb(247, 246, 246)' }}
              >
                <img src={vitoPhoto} className='reiningung-img' />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Rent;

import React from 'react';
import './style.css';
//import '../snowflake.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import grayLine from '../assets/gray_line.png';
import lineIcon from '../assets/gray-line.png';
import originalPhoto from '../assets/f-after-cc.jpg';
import GestaltungVonInnenwandenIcon from '../assets/icons/serviceIcons/GestaltungVonInnenwandenIcon.svg';
import GestaltungVonDeckenIcon from '../assets/icons/serviceIcons/GestaltungVonDeckenIcon.svg';
import AussenfassadenIcon from '../assets/icons/serviceIcons/AussenfassadenIcon.svg';
import ReinigungIcon from '../assets/icons/serviceIcons/ReinigungIcon.svg';
import umzugsmaler from '../assets/umzugsmaler.jpg';
import discussion from '../assets/discussion.jpg';
import reinigung from '../assets/reinigung/reinigung.jpeg';

const HomePage: React.FC = () => {
  return (
    <div>
      <div className='container-fluid'>
        <div className='row' style={{ justifyContent: 'center' }}>
          <div className='col-lg-6 p-0'>
            <div className='headerContent'>
              <h2 className='font-weight-bold'>Facility24</h2>
              <img src={grayLine} alt='Line' />
              <span className='descriptionText'>
                Ihr Partner für persönliche und professionelle
                Renovierungsarbeiten an Liegenschaften aller Art.
              </span>
              <span>
                Von Malerarbeiten im Treppenhaus, in der Wohnung, sowie an der
                Aussenfassade bis hin zur Endabnahme-Reinigung, können wir Ihnen
                ein auf Sie zugeschnittenes Komplettpaket nach Ihren Wünschen
                anbieten.
              </span>
              <a href='../Contact' className='main-button'>
                Kontaktaufnahme
              </a>
            </div>
          </div>
          <div className='col-lg-6 p-0' style={{ maxWidth: 'fit-content' }}>
            <figure className='cd-image-container'>
              <img src='../assets/f-before-c.jpg' alt='Original Image' />
              <span className='cd-image-label' data-type='original'>
                Vorher
              </span>
              <div className='cd-resize-img'>
                <img src={originalPhoto} alt='Modified Image' />
                <span className='cd-image-label' data-type='modified'>
                  Nachher
                </span>
              </div>
              <span className='cd-handle'></span>
            </figure>
          </div>
        </div>
        <div className='row black-row'>
          <div className='d-flex flex-column' style={{ alignItems: 'center' }}>
            <h3 className='black-section-title'>
              Warum mit uns zusammenarbeiten?
            </h3>
            <img
              style={{ marginTop: '10px' }}
              src={grayLine}
              className='facility-question'
            />
          </div>
          <div className='partners'>
            <div>
              <h5>Ausgezeichneter & Zuverlässiger Service!</h5>
            </div>
            <div>
              <h4>Preiswert</h4>
            </div>
            <div>
              <h5>Qualität & Sauberkeit</h5>
            </div>
            <div>
              <h4>Bis zu 40% auf Ihren Auftrag sparen</h4>
            </div>
          </div>
        </div>
        <div className='container'>
          <div className='row d-flex third-section mb-4'>
            <div className='third-section-content'>
              <p style={{ fontSize: '30px' }}>
                Unser eingespieltes Team vom Malereibetrieb & Reinigung,{' '}
                <b>Facility24</b> in Chur,
                <br />
                <b style={{ fontSize: '36px' }}>
                  ist ihr leistungsstarker Partner rund um die
                </b>
              </p>
              <img src={lineIcon} alt='Line' />
            </div>
            <div className='row mt-5 d-flex justify-content-center align-items-center'>
              <div className='col-md-6'>
                <img src={GestaltungVonInnenwandenIcon} />
                <h4>Gestaltung von Innenwänden,</h4>
              </div>
              <div className='col-md-6'>
                <img src={GestaltungVonDeckenIcon} />
                <h4>Gestaltung von Decken,</h4>
              </div>
              <div className='col-md-6'>
                <img src={AussenfassadenIcon} />
                <h4>Aussenfassaden</h4>
              </div>
              <div className='col-md-6'>
                <img src={ReinigungIcon} />
                <h4>Reinigung</h4>
              </div>
            </div>
          </div>
        </div>
        <div style={{ textAlign: 'center' }}>
          <div className='maler_beratung_reinigung'>
            <div className='row mt-5'>
              <div
                className='col-lg-5'
                style={{
                  paddingTop: '80px',
                  paddingRight: '50px',
                  backgroundColor: 'rgb(247, 246, 246)',
                }}
              >
                <h4>
                  <b>Maler</b>
                </h4>
                <span>
                  Etwas Besonderes soll es sein, das Ihren Räumlichkeiten das
                  gewisse Etwas verleiht? Natürlich können Sie sich selbst an
                  dieser Aufgabe versuchen – doch wenn es wirklich schön werden
                  soll, sind Sie mit einem Fachmann für Malerarbeiten besser
                  beraten.
                </span>
              </div>
              <div
                className='col-lg-4'
                style={{ backgroundColor: 'rgb(247, 246, 246)' }}
              >
                <img src={umzugsmaler} className='reiningung-img' />
              </div>
            </div>
          </div>
          <div className='maler_beratung_reinigung'>
            <div className='row mt-5'>
              <div
                className='col-lg-4 discussion'
                style={{ backgroundColor: 'rgb(247, 246, 246)' }}
              >
                <img src={discussion} className='reiningung-img' />
              </div>
              <div
                className='col-lg-5'
                style={{
                  paddingTop: '80px',
                  backgroundColor: 'rgb(247, 246, 246)',
                }}
              >
                <h4>
                  <b>Kostenlose Beratung</b>
                </h4>
                <span>
                  Melden Sie sich für eine kostenlose Offerten-Gespräch vor Ort
                  an! Wir werden Ihre individuelle Situation besprechen und die
                  Lösung finden, die perfekt auf Ihre Anforderungen
                  zugeschnitten ist.
                </span>
              </div>
            </div>
          </div>
          <div className='maler_beratung_reinigung'>
            <div className='row mt-5'>
              <div
                className='col-lg-5'
                style={{
                  paddingTop: '80px',
                  paddingRight: '50px',
                  backgroundColor: 'rgb(247, 246, 246)',
                }}
              >
                <h4>
                  <b>Reinigung</b>
                </h4>
                <span>
                  Von der Gebäudereinigung über die Büroreinigung und
                  Unterhaltsreinigung bis hin zur Wohnungsreinigung. Ihr Gebäude
                  ist bei uns in den besten Händen.
                </span>
              </div>
              <div
                className='col-lg-4'
                style={{ backgroundColor: 'rgb(247, 246, 246)' }}
              >
                <img src={reinigung} className='reiningung-img' />
              </div>
            </div>
          </div>
        </div>
        <div style={{ textAlign: 'center', marginTop: '50px' }}>
          <h3>
            Nehmen Sie uns <b>beim Wort</b>
          </h3>
          <br />
          <img src={lineIcon} className='facility-question' />
          <p
            style={{
              paddingLeft: '11%',
              paddingRight: '11%',
              paddingTop: '3%',
              paddingBottom: '4%',
            }}
          >
            Ob Decken, Wände und Fenster streichen oder Verputzarbeiten innen –
            gerne erstellen wir für Sie als erfahrene Maler ein stimmiges
            Konzept und setzen dies in höchster Präzision um.
            <br />
            <br />
            Innere Malerarbeiten vom Profi sorgen für eine Aufwertung von Räumen
            aller Art. Ob für private Wohnungen, Büros oder Geschäftsräume –
            durch einen professionellen Anstrich geniessen Sie die Sicherheit,
            über Jahre hinweg von einem attraktiven Anblick Ihrer Innenräume zu
            profitieren. Neben dem Einsatz von professionellen Materialien und
            hochwertigen Farben stehen wir Ihnen im Vorfeld gerne zur Seite, um
            individuelle Lösungen passend zu Ihren Räumlichkeiten und Ihrem
            Interieur zu entwickeln.
            <br />
            Nehmen Sie einfach Kontakt auf, wenn Sie mehr über
            Malerarbeiten/Reinigungsarbeiten erfahren möchten und lassen Sie
            sich unverbindlich ein Angebot von uns erstellen!
          </p>
        </div>
      </div>
    </div>
  );
};

export default HomePage;

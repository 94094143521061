import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css';
import blueLine from '../assets/blue-line.png';
import fahrdienstImage from '../assets/fahrdienst24/fahrdienst24.jpg';
import blueLineImage from '../assets/blue_line.png';
import teslaImage from '../assets/fahrdienst24/tesla.jpg';
import teslaImage2 from '../assets/fahrdienst24/DSC06996.jpg';
import EventsIcon from '../assets/Events.png';
import HochzeitenIcon from '../assets/Hochzeiten.png';
import KonzertenIcon from '../assets/Konzerten.png';
import MessenIcon from '../assets/Events.png';
import SportIcon from '../assets/sport.png';
import DorfUndStadtfesteIcon from '../assets/Dorf- und  Stadtfeste.png';
import FirmenfesteIcon from '../assets/Firmenfeste.png';
import GeburtstagsfeiernIcon from '../assets/Geburtstagsfeiern.png';
import FlughafentransferIcon from '../assets/icons/serviceIcons/FlughafentransferIcon.svg';
import FahrserviceAufHohemNiveauIcon from '../assets/icons/serviceIcons/FahrserviceAufHohemNiveauIcon.svg';
import ShuttleServiceIcon from '../assets/icons/serviceIcons/Hotel-ShuttleServiceIcon.svg';
import GeschaftreisenIcon from '../assets/icons/serviceIcons/GeschaftreisenIcon.svg';
import AbholdienstIcon from '../assets/icons/serviceIcons/AbholdienstIcon.svg';
import ServiceIcon from '../assets/icons/serviceIcons/Chauffeur-ServiceIcon.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faBars } from '@fortawesome/free-solid-svg-icons';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { faBars } from '@fortawesome/free-solid-svg-icons';

const App: React.FC = () => {
  return (
    <div>
      <div className='container-fluid'>
        <div className='fahrdienstBgLeft' />
        <div className='row'>
          <div className='col-lg-6 p-0'>
            <div className='headerContent'>
              <div>
                <h3 className='font-weight-bold'>Fahrdienst24</h3>
              </div>
              <img src={blueLine} alt='Line' />
              <span className='descriptionText'>
                Bewegung ist unsere Kraft. Wir sind mit Herzblut dabei. Das ist
                unsere Lebensader. Dafür stehen wir jeden Morgen auf. Daraus
                ziehen wir die Motivation, die Mobilität neu zu gestalten. Für
                Sie. Für all die Orte, zu denen Sie fahren möchten.
              </span>
              <a href='../Contact' className='main-button'>
                Kontaktaufnahme
              </a>
            </div>
          </div>
          <div className='col-lg-6 p-0 fahrdienst-main-image'>
            <img
              src={fahrdienstImage}
              alt='Original Image'
              className='image-main-24'
            />
          </div>
        </div>

        <div className='container'>
          <div className='row d-flex third-section mb-4'>
            <div className='third-section-content'>
              <h3>
                Ihre Sicherheit ist <b>unser Antrieb</b>
              </h3>
              <img src={blueLineImage} alt='Line' />
              <span>
                Wir helfen den Fahrgästen schnell, bequem und sicher zum Zielort
                zu gelangen. Außerdem erleichtern wir den Zugang zum
                Gesundheitswesen, schaffen neue Möglichkeiten zur Frachtbuchung
                und helfen Unternehmen, ihren Mitarbeitern ein nahtloses
                Fahrterlebnis zu ermöglichen.
              </span>
              <span>
                Neben dem sicheren und pünktlichen Transport, bemühen wir uns um
                einen preiswerten und transparenten Tarif. Unseren Kunden stehen
                verschiedene Möglichkeiten zur Buchung und Bezahlung einer Fahrt
                zur Auswahl. Wir möchten Ihnen die Fahrt so angenehm und
                komfortabel wie nur möglich gestalten.
              </span>
            </div>
            <div className='row mt-5 d-flex justify-content-center align-items-center'>
              <div className='col-lg-4'>
                <img src={FlughafentransferIcon} />
                <h4>Flughafentransfer</h4>
                <span>Fahrten an diverse Flughäfen.</span>
              </div>
              <div className='col-lg-4'>
                <img src={FahrserviceAufHohemNiveauIcon} />
                <h4>Fahrservice auf hohem Niveau</h4>
                <span>Neuere Wagen mit mehr Beinfreiheit.</span>
              </div>
              <div className='col-lg-4'>
                <img src={ShuttleServiceIcon} />
                <h4>Hotel-Shuttle Service</h4>
                <span>
                  Moderne Limousinen oder Kleinbusse für Geschäftsreisen.
                </span>
              </div>
              <div className='col-lg-4'>
                <img src={GeschaftreisenIcon} />
                <h4>Geschäftsreisen</h4>
                <span>Hoteltransfer oder Gepäcklieferungen.</span>
              </div>
              <div className='col-lg-4'>
                <img src={AbholdienstIcon} />
                <h4>Kurier/Abholdienst</h4>
                <span>Kurierdienst und Gütertransport.</span>
              </div>
              <div className='col-lg-4'>
                <img src={ServiceIcon} />
                <h4>Chauffeur-Service</h4>
                <span>Wir fahren Ihr Fahrzeug.</span>
              </div>
            </div>
          </div>

          <div
            className='last-section'
            style={{ backgroundColor: 'aliceblue' }}
          >
            <h4 style={{ textAlign: 'center' }}>
              <b>Nachhaltigkeit / TESLA</b>
            </h4>
            <div className='row mt-5'>
              <div className='col-lg-1'></div>
              <div className='col-lg-5 right-col' style={{ marginTop: '30px' }}>
                <span>
                  Fahrdienst24 will sich in Zukunft zu einem vollelektrischen
                  und emissionsfreien Fahrdienst entwickeln: 100 % der Fahrten
                  sollen mit emissionsfreien Fahrzeugen durchgeführt werden.
                </span>
                <br />
                <br />
              </div>
              <div className='col-lg-1'></div>
              <div className='col-lg-5'>
                <img src={teslaImage} className='tesla-img' />
              </div>
            </div>
            <div className='row mt-1'>
              <div className='col-lg-1'></div>
              <div className='col-lg-5'>
                <img src={teslaImage2} className='tesla-img' />
              </div>
              <div className='col-lg-1'></div>
              <div
                className='col-lg-5 right-col'
                style={{ alignSelf: 'center' }}
              >
                <br />
                <br />
                <span>
                  In diesem Rahmen: – bieten wir Fahrgästen mehr Möglichkeiten,
                  umweltfreundlich zu fahren, um eine saubere und gerechte
                  Energiewende zu beschleunigen.
                </span>
              </div>
            </div>
          </div>

          <div className='container'>
            <div className='row d-flex third-section mb-4'>
              <div className='third-section-content'>
                <p style={{ fontSize: '30px' }}>
                  Das höchste Anliegen der Fahrdienste 24 ist es,
                  <b>
                    unseren Fahrgästen eine sehr gute Serviceleistung zu
                    erbringen.
                  </b>
                </p>
                <img src={blueLineImage} alt='Line' />
                <br />
                <br />
                <p>
                  Ihre Sicherheit ist wichtig – egal, ob Sie auf der Rückbank
                  oder als Beifahrer mitfahren. Wir wollen unseren Beitrag
                  leisten und Technologie spielt dabei eine entscheidende Rolle.
                </p>
                <p>
                  Planen Sie einen Geschäftsausflug, eine Reise mit Ihrem Verein
                  oder Kundenanlass? Wir organisieren für Sie die Einzelheiten
                  und Attraktionen. Möchten Sie ein Konzert- oder Sportanlass
                  besuchen oder müssen frühzeitig zum Flughafen? Befinden Sie
                  sich in einer notgedrungenen Lage wegen Ihrem Fahrzeug? Wir
                  bringen Sie sicher an Ihr gewünschtes Reiseziel. Gedenken Sie
                  mit Ihrem Seniorenclub einen interessanten Ausflug zu planen,
                  dann sind wir für Sie der richtige Partner und haben für alle
                  Bedürfnisse die richtige Lösung.
                </p>
              </div>
              <div className='container'>
                <div className='row mt-5 d-flex justify-content-center align-items-center'>
                  <div className='col-md-6 col-lg-3'>
                    <div data-aos='flip-down' data-aos-duration='1200'>
                      <img src={EventsIcon} />
                      <h4>Events</h4>
                    </div>
                  </div>
                  <div className='col-md-6 col-lg-3'>
                    <div data-aos='flip-down' data-aos-duration='1200'>
                      <img src={HochzeitenIcon} />
                      <h4>Hochzeiten</h4>
                    </div>
                  </div>
                  <div className='col-md-6 col-lg-3'>
                    <div data-aos='flip-down' data-aos-duration='1200'>
                      <img src={KonzertenIcon} />
                      <h4>Konzerten</h4>
                    </div>
                  </div>
                  <div className='col-md-6 col-lg-3'>
                    <div data-aos='flip-down' data-aos-duration='1200'>
                      <img src={MessenIcon} />
                      <h4>Messen</h4>
                    </div>
                  </div>
                  <div className='col-md-6 col-lg-3'>
                    <div data-aos='flip-down' data-aos-duration='1200'>
                      <img src={SportIcon} />
                      <h4>Sport</h4>
                    </div>
                  </div>
                  <div className='col-md-6 col-lg-3'>
                    <div data-aos='flip-down' data-aos-duration='1200'>
                      <img src={DorfUndStadtfesteIcon} />
                      <h4>Dorf- und Stadtfeste</h4>
                    </div>
                  </div>
                  <div className='col-md-6 col-lg-3'>
                    <div data-aos='flip-down' data-aos-duration='1200'>
                      <img src={FirmenfesteIcon} />
                      <h4>Firmenfeste</h4>
                    </div>
                  </div>
                  <div className='col-md-6 col-lg-3'>
                    <div data-aos='flip-down' data-aos-duration='1200'>
                      <img src={GeburtstagsfeiernIcon} />
                      <h4>Geburtstagsfeiern</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
